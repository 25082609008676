<template>
  <!--涉及到多级路由参数-->
  <div class="page">

    <!--居中对齐-->
    <div class="notice-wrapper" v-show="ui.noticeBarStatus">
      <div class="center">
        <el-button
            size="small"
            :plain="false"
            type="primary"
            style="opacity: 0.5;display: block;width: 100%;margin-top: 5px;white-space: nowrap;"
            @click="close()">
          <i class="fa fa-warning i-gap"></i>
          请点击 " 对话框 " 右上角 <i class="fa fa-close"></i> 按钮，以
          <span style="color:red">" 正常关闭 "</span> 弹窗！
        </el-button>
      </div>
    </div>

    <!--表单滚动区域-->
    <div class="table-wrapper">

      <el-table
          ref="multipleTable"
          :data="tableData"
          :height="table.height"
          :row-key="table.key"
          :header-cell-style="{fontSize: '15px',fontWeight: '100',backgroundColor:'#FAFAFA',height: '48px'}"
          style="width: 100%"
          stripe
          border>

        <el-table-column
            type="index"
            align="center"
            label-class-name="row-sn"
            width="50">
        </el-table-column>

        <el-table-column
            prop="comment"
            label="栏目名称"
            sortable
            width="250">
        </el-table-column>

        <el-table-column
            prop="width"
            label="宽度-像素"
            sortable
            align="center"
            width="150">
          <template slot-scope="scope">
            <el-tag
                size="small"
                effect="dark"
                class="label">
              {{scope.row['width']}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            prop="sortIndex"
            label="排序值-正序"
            sortable
            align="center"
            width="150">
          <template slot-scope="scope">
            <el-tag
                size="small"
                effect="dark"
                class="label">
              {{scope.row['sortIndex'] - 1}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            prop="search"
            label="是否允许检索"
            sortable
            align="center"
            width="150">
          <template slot-scope="scope">
            <el-switch
                :active-value="true"
                :inactive-value="false"
                v-model="scope.row['search']"
                disabled>
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
            prop="statistics"
            label="是否参与合计"
            sortable
            align="center"
            width="150">
          <template slot-scope="scope">
            <el-switch
                :active-value="true"
                :inactive-value="false"
                v-model="scope.row['statistics']"
                disabled>
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
            prop="align"
            label="对齐方式"
            sortable
            align="center"
            width="120">
          <template slot-scope="scope">
            <span class="navbar-bars">
            <el-button
                :plain="true"
                size="mini"
                class="fa_icon">
            <i :class="'fa fa-'+(scope.row['align']=='right'?'align-right':(scope.row['align']=='center'?'align-center':'align-left'))"
               size="mini"></i>
            </el-button>
            </span>
          </template>
        </el-table-column>

        <el-table-column
            prop="updateDate"
            label="更新时间"
            sortable
            align="center">
          <template slot-scope="scope">
            <span class="datetime">
            <i v-if="scope.row['updateDate']!=null && scope.row['updateDate']!=''"
               class="i-gap fa fa-calendar"></i>{{scope.row['updateDate']}}
            </span>
          </template>
        </el-table-column>

        <el-table-column
            prop="hidden"
            align="center"
            label="是否隐藏-可设置"
            fixed="right"
            width="150">
          <template slot-scope="scope">
            <el-switch
                :active-value="true"
                :inactive-value="false"
                v-model="scope.row['hidden']"
                @change="switchChange($event, scope.row['name'])">
            </el-switch>
          </template>
        </el-table-column>

      </el-table>

    </div>


  </div>
</template>

<script>

import {setTable, getTable} from '@/utils/auth'
import {getTableCaption} from '@/api/public.js'

export default {
  name: "setTableColumn",
  data() {
    return {

      // 界面参数
      ui: {
        noticeBarStatus: true,
        labelWidth: '120px',
        placeHolder: false,
        submitDisabled: false,
        count: 6,
      },

      table: {
        key: "true",
      },

      // 输入参数
      caption: {

        tableName: '',
        tableAlias: '',
        tableTitle: '',
        keyIdName: '',
        title: '编辑',
      },

      // 表单数据
      form: {},

      // 数据
      tableData: [],

    }
  },
  created() {

    // 初始化
    this.initParm();
    if (this.caption.tableName) {
      this.initCaption();
    }
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  beforeUpdate() {

  },
  updated() {
    let element = document.querySelectorAll('.el-form-item:not(.place-holder)');
    if ((element.length % 2) == 1) {
      this.ui.placeHolder = true;
    }
  },
  activated() {

  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;

      // 参数到模型
      for (let key in e) {
        if (key == "tableName") {
          let tableName = e[key];
          let tableAlias = tableName.replace(/_/g, "");
          tableAlias = tableAlias.toLowerCase();
          this.caption.tableAlias = tableAlias;
          this.caption[key] = tableName;
        } else {
          this.caption[key] = e[key];
        }
      }

      // 标题-动作形式
      this.setResize();

    },

    // 初始化数据格式
    initCaption() {
      getTableCaption(this.caption.tableName).then(res => {
        this.caption['colModel'] = res['colModel'];
        this.initData();
        this.$forceUpdate();
      });
    },

    // 初始化数据
    initData() {
      let ret = getTable(this.caption.tableName);
      let storeColModel = ret["colModel"];
      if (storeColModel) {
        let scmMap = {};
        for (let i in storeColModel) {
          let scm = storeColModel[i];
          scmMap[scm['name']] = scm;
        }
        for (let i in this.caption['colModel']) {

          let cm = this.caption['colModel'][i];
          if (!cm.key && !cm['linkTableName']) {
            let scm = scmMap[cm['name']];
            if (scm) {

              cm['hidden'] = scm['hidden'];
              cm['width'] = scm['width'];
              cm['sortIndex'] = scm['sortIndex'];
              cm['updateDate'] = scm['updateDate'];

              this.tableData.push({
                comment: cm['comment'],
                name: cm['name'],
                width: cm['width'],
                sortIndex: cm['sortIndex'],
                hidden: cm['hidden'],
                updateDate: cm['updateDate'],
                align: cm['align'],
                search: cm['search'],
                statistics: cm['statistics'],
              });

            }
          }
        }

      }
    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {

        this.table.height = document.documentElement.clientHeight - 70;

      });
    },

    // 关闭
    close() {
      window.parent.closeDialog();
    },

    // 开关
    switchChange($event, name) {

      let ret = getTable(this.caption.tableName);
      let storeColModel = ret["colModel"];
      if (storeColModel) {
        for (let i in storeColModel) {
          let scm = storeColModel[i];
          if (scm['name'] == name) {
            scm['hidden'] = $event
            scm['updateDate'] = new Date().toLocaleString()
            break;
          }
        }
      }

      setTable(this.caption.tableName, storeColModel);

      // 更新，有缺陷，影响排序
      if (this.table.key == 'true') {
        this.table.key = 'false';
      } else {
        this.table.key = 'true';
      }

      this.$forceUpdate();

      window.parent.refreshIframeCaption();

    },

  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {

  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*--滚动条样式--*/
#app .el-table__body-wrapper::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);
  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--通知性质栏--*/
.notice-wrapper {

  margin: 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

}

/*--小按钮--*/
.navbar-bars {

  display: block;

  .el-button {
    padding: 7px 8px;
    margin-left: 5px;
    margin-right: 0px;
    /*background-image: linear-gradient(0deg, #4094ff 5%, #F4F4F4 10%);*/
    color: #909399;
    border-radius: 10px;
  }
}

/*--表格--*/
.table-wrapper {

  margin: 0px 10px;
  margin-top: 10px;

  background: #FFF;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  .el-table--border, .el-table--group {
    border: 0px;
  }

  .el-table thead .cell {
    white-space: nowrap;
    word-break: break-all;
  }

  .el-table thead .el-table__cell {
    padding: 0px !important;
    max-height: 45px !important;
    height: 45px !important;
  }

  .el-table tbody .el-table__cell {

    opacity: 0.95 !important;
    color: #676a6c !important;
    padding: 8px 0px !important;

    min-height: 45px !important;
    height: 45px !important;
  }

  .el-table {
    background: url(../../static/images/bg.jpg);
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  /*--主键排序--*/
  .el-table th.row-sn:nth-child(1) > div:before {
    content: "\f292";
    font-weight: 100;
    font: normal normal normal 12px/1 FontAwesome;
    padding: 3px 5px;
    border-radius: 5px;
  }

}

/*--日期--*/
.datetime {
  color: #909399;
  font-family: "Helvetica Neue", "open sans", Helvetica, Arial, sans-serif;

  i {
    color: #c7c7c7;
  }
}

/*--标签--*/
.label {
  background-color: rgb(160, 207, 255);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  border: 0px;
  height: 22px;
  line-height: 22px;
  padding: 0px 10px;
}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

.i-gap {
  margin-right: 5px;
}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }
  .place-holder {
    display: none;
  }

}

</style>